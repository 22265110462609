/* Global */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');
:root {
  --main-color: #f9b52e;
  --background-color: #191923;
  --bc-color2: #1e1e28;
  --side-top: #252532;
  --white-color: #fff;
  --elements-back: #2c2c38;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: var(--white-color);
  outline: none;
  scroll-behavior: smooth;
  transition: all 0.4s ease-in-out;
}
body {
  background-color: var(--background-color);
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
a {
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 1px;
}

.master {
  margin: 20px 40px;
  box-shadow: 0 3px 8px 0 rgb(15 15 20 / 20%);
  position: relative;
  display: flex;
  flex-direction: row;
}
.swal2-html-container {
  color: var(--elements-back) !important;
  font-size: bold;
}
/* Loader Screen */
.loaderScreen {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loaderScreen p {
  margin-bottom: 15px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.85px;
}
.smallScreenLoader {
  margin-top: -120px;
}

/* Navbar #header */

nav {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.navbarCover,
.sidebarCover {
  display: none;
}
.navLinks span {
  margin-right: 45px;
}
.navLink {
  padding: -30px;
  position: relative;
}
.navLink::before,
.activeHeaderLink::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: var(--main-color);
  /* transition: 0.4s ease-in-out; */
}
.navLink:hover::before {
  transition: 0.4s ease-in-out;
  width: 100%;
}
.activeHeaderLink::before {
  width: 100%;
}
img.gotoHome {
  width: 40px;
  display: none;
}
.menuIcon,
.closeIcon,
.sideBarIcon {
  font-size: 20px;
  display: none;
}
.downloader {
  border-bottom: 0;
  border: 1.5px solid var(--main-color);
  padding: 3px 15px;
  transition: 0.19s ease-in-out;
  border-radius: 30px;
  cursor: pointer;
}
.downloader:hover {
  background-color: var(--main-color);
  color: var(--background-color);
  transition: 0.19s ease-in-out;
}
/* SideBar */
.sideBar {
  position: relative;
  width: 370px;
  background-color: var(--bc-color2);
  height: 95vh;
  border-radius: 10px;
  overflow: auto;
}

.profile {
  background: var(--side-top);
  height: 235px;
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 4px 0 rgb(15 15 20 / 10%);
  justify-content: center;
  border-bottom: 2px solid var(--background-color);
}
.profilePhoto {
  width: 90px;
  margin-bottom: 10px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
.profileImageOnline {
  position: relative;
}
.profileImageOnline::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 20px;
  right: 10px;
  width: 12px;
  z-index: 99;
  height: 12px;
  pointer-events: none;
  background-color: var(--main-color);
  border-radius: 50%;
  -webkit-animation: pulse-animation 0.85s infinite;
  animation: pulse-animation 0.85s infinite;
}
.myStatus {
  position: absolute;
  background-color: var(--background-color);
  font-size: 9px;
  text-align: left;
  padding: 5px 10px;
  right: -97px;
  bottom: 0;
  width: 79px;
  color: rgba(255, 255, 255, 0.452);
  box-shadow: 0 1px 4px 0 rgb(15 15 20 / 10%);
  transform: translateX(-10px);
  opacity: 0;
  transition: 0.4s ease-in-out;
}
.profileImageOnline:hover > .myStatus {
  display: inherit;
  transition: 0.4s ease-in-out;
  transform: translateX(0px);
  opacity: 1;
}
@-webkit-keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px #f9b52e98;
  }
  100% {
    box-shadow: 0 0 0 5px #f9b52e1c;
  }
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px #f9b52e98;
  }
  100% {
    box-shadow: 0 0 0 5px #f9b52e1c;
  }
}

.profile h4 {
  font-weight: 600;
}
.profileDetailsSection {
  padding: 0 25px;
}
.profile p {
  margin-top: 10px;
  line-height: 15px;
  font-size: 12px;
  font-weight: 300;
  color: #8c8c8e;
}
.profileDetails {
  width: 100%;
  z-index: 1000;
  background-color: var(--bc-color2);
  height: calc(95vh - (237px + 50px));
  overflow: auto;
  /* padding: 15px 0; */
}
.profileDetails hr {
  border: 0;
  background-color: #646466;
  height: 1px;
  width: 100%;
  opacity: 0.3;
  margin: 15px 0;
}
/* .profileDetails .profileDetailsGap {
  padding: 15px 0;
} */
.technologyKnown span {
  line-height: 15px;
  font-size: 12px;
  font-weight: 300;
  color: #8c8c8e;
}
.profileTechnology h6 {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
}
.technologyKnown svg {
  fill: var(--main-color);
  margin-right: 10px;
  width: 10px;
  font-size: 10px;
}
.addressBar {
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  line-height: 1.8;
}
.leftAddressBar h6 {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
}
.rightAddressBar span {
  margin-bottom: 5px;
  display: block;
  text-align: right;
  font-size: 12px;
  font-weight: 300;
  color: #8c8c8e;
}

.sideBarFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--side-top);
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.sideBarFooter a svg {
  fill: var(--white-color);
}
.downloadResume {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.downloadResume h6 {
  font-weight: 900;
  letter-spacing: 0.8px;
  margin-right: 8px;
  color: #8c8c8e;
}
.downloadResume svg {
  fill: #8c8c8e;
}
.downloadResume:hover > h6,
.downloadResume:hover > svg {
  color: #fff;
  fill: #fff;
}

.mostTechnolgiesWorked {
  display: flex;
  justify-content: center;
  margin: -5px 0 -5px;
}

.progressBarSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
}
.progressBarSection p {
  font-size: 12px;
  color: #fff;
  margin-top: -15px;
}
.RCP {
  position: relative;
}
.progressBarSection span {
  position: sticky;
  font-size: 8px;
  margin-bottom: 35px;
  margin-top: -50px;
  color: #8c8c8e;
}

/* Banner */

.banner {
  width: 100%;
  border-radius: 10px;
  height: 420px;
  display: block;
  margin-top: 20px;
  background-image: url(./assets/Images/bg.jpg);
  background-size: 100% 500px;
  background-repeat: no-repeat;
  background-position: bottom;
}
.bannerOverlay {
  display: block;
  width: calc(100%);
  height: 100%;
  background-image: linear-gradient(
    90deg,
    rgba(45, 45, 58, 0.9) 15%,
    rgba(45, 45, 58, 0.7) 50%,
    rgba(43, 43, 53, 0.7) 100%
  );
  background-size: cover;
  background-position: center;
  border-radius: 0 0 10px 10px;
}
.bannerContents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  height: 100%;
  padding: 60px 70px;
}
.bannerImg img {
  width: 360px;
  position: absolute;
  top: -17px;
  right: 100px;
  -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.445));
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.445));
  background-position: -5px -1410px;
}

.bannerText h1 {
  font-weight: 800;
  font-size: 52px;
  letter-spacing: 3px;
  margin-bottom: 15px;
}
.banner p,
.banner span,
.codeFont {
  font-family: 'Courier Prime', monospace;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-size: 14px;
}
.banner p span {
  color: var(--main-color);
}
.bannerText p {
  display: block;
  height: 40px;
}
/* Button */
.commonButton {
  background-color: var(--main-color);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  margin-top: 25px;
  text-transform: uppercase;
  border-radius: 3px;
  transition: 0.25s ease-in-out;
}
.commonButton:hover {
  margin-top: 23px;
  transition: 0.25s ease-in-out;
}
.contactForm span input:hover {
  transform: translateY(-2px);
  transition: 0.25s ease-in-out;
}
.commonButton a,
.contactForm span input {
  color: var(--background-color);
  letter-spacing: 1.5px;
  font-size: 11px;
  font-weight: 600;
  display: block;
  padding: 12px 35px;
  text-transform: uppercase;
}

/* Counter */
.counterSection {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 30px 0;
}
.counterSection div {
  display: flex;
  width: 100%;
}
.counterNumber {
  color: var(--main-color);
  font-size: 22px;
  font-weight: 600;
}
.counterName {
  font-size: 14px;
  font-weight: 400;
  margin-left: -140px;
}

.sectionTitle {
  font-weight: 600;
  font-size: 17px;
}

/* ServiceBox */
.serviceBoxSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.serviceBox {
  display: block;
  background-color: var(--elements-back);
  padding: 30px;
  margin: 15px 0;
  box-shadow: 0 1px 4px 0 rgb(15 15 20 / 10%);
  flex-basis: 27%;
  border-radius: 10px;
}

.serviceBox h5 {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}

.serviceBox p {
  margin-bottom: 15px;
  font-size: 12px;
  color: #8c8c8c;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.serviceBoxLink {
  color: var(--main-color);
  margin: 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.serviceBoxLink::after {
  content: ' >';
  font-size: 15px;
  transition: 0.2s ease-in-out;
}

.serviceBoxLink:hover::after {
  padding-left: 2px;
  transition: 0.2s ease-in-out;
}

/* Footer */
footer {
  width: 100%;
  height: 50px;
}
.footer {
  padding: 10px 20px;
  background-color: var(--bc-color2);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer p,
.footer a {
  font-size: 11px;
  color: #8c8c8e;
}
.footer a {
  color: #fff;
  color: var(--main-color);
  position: relative;
}
.footer a::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: var(--main-color);
  transition: 0.2s ease-in-out;
}
.footer a:hover::after {
  width: 100%;
  transition: 0.2s ease-in-out;
}
/* Contact Page */

.contactSection .sectionTitle {
  margin: 30px 0;
}

/* Contact Information */

.contactInformation {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px 0;
}
.contactBox,
.contactForm {
  border-radius: 10px;
  padding: 30px 30px 25px;
  background-color: var(--elements-back);
  width: 100%;
}
.contactBox {
  box-shadow: 0 3px 8px 0 rgb(15 15 20 / 20%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 24%;
}
.contactForm {
  padding: 0;
}
.contactForm form {
  padding: 30px;
}
/* Contact Form */
.contactForm p {
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: var(--side-top);
  margin-bottom: 15px;
  position: relative;
}
.contactForm p input,
.contactForm p textarea {
  width: 100%;
  height: 50px;
  padding: 0 10px;
  background-color: var(--side-top);
  color: var(--white-color);
  border: 0;
  outline: none;
  border-left: 50px solid var(--bc-color2);
}
.contactForm p input {
  height: 50px;
  border-radius: 3px;
}
.contactForm p textarea {
  padding: 10px;
  height: 116px;
  resize: vertical;
}
.contactForm p label {
  color: var(--white-color);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: text;
  justify-content: center;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}
.contactForm p input:focus,
.contactForm p textarea:focus {
  border-left: 50px solid var(--main-color);
}

.contactform span {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.contactForm span input {
  background-color: var(--main-color);
  border-radius: 3px;
  outline: none;
  border: 0;
  height: 100%;
  margin-top: 25px;
  cursor: pointer;
}

/* Blogs */
.blogSection {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  flex-wrap: wrap;
  width: 100%;
}
.blogPostBox {
  flex-basis: 49%;
  background-color: var(--elements-back);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  margin-bottom: 20px;
  -webkit-animation: projectGrow 0.4s;
  animation: projectGrow 0.4s;
  border-radius: 10px;
}
.blogMainImage {
  width: 100%;
  height: 310px;
  overflow: hidden;
}
.blogPostBox img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
}
.blogPostBox img:hover {
  transform: scale(1.1);
}
.postText {
  padding: 30px;
}
.postText .sectionTitle {
  font-size: 16px;
}
.postText p {
  font-weight: lighter;
  font-size: 13px;
  margin: 10px 0;
}
.postBoxTitle {
  margin-bottom: 10px;
}
.blogPostBanner {
  width: 100%;
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
}

.blogPostBanner img {
  -webkit-filter: brightness(0.9);
  filter: brightness(0.9);
}
.blogPostBanner img:hover {
  -webkit-filter: brightness(1);
  filter: brightness(1);
  transform: scale(1.02);
}
.mainBlogSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: hidden;
}
.mainBlogSection article {
  background-color: var(--elements-back);
  border-radius: 10px;
}
.mainBlogSection article {
  flex-basis: 71%;
  max-width: 71%;
}
.mainBlogSection .authorSection {
  flex-basis: 28%;
}
.article {
  padding: 30px;
}
.blogTitle {
  font-size: 24px;
  margin: 10px 0;
}
.authorSection .profileDetailsSection {
  background-color: var(--elements-back);
  padding: 25px;
  border-radius: 10px;
}
/* Blog Designing */

.mainBlogSection .article h1 {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0 15px;
}
.mainBlogSection .article h2 {
  font-size: 17px;
  margin: 20px 0 15px;
}
.mainBlogSection .article h3 {
  font-size: 15px;
  margin: 20px 0 10px;
}

.mainBlogSection .article h4 {
  display: none;
}
.mainBlogSection .article blockquote {
  font-style: italic;
  padding: 10px 15px;
  color: var(--white-color);
  font-size: 14px;
  position: relative;
  margin-bottom: 15px;
}
.mainBlogSection .article blockquote::before {
  content: '';
  position: absolute;
  background-color: var(--white-color);
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
}
.blogCodeSection {
  width: 100%;
}
.blogCode {
  background-color: var(--side-top) !important;
  border-radius: 5px;
  margin: 15px;
  font-size: 1rem;
}
.blogCode::-webkit-scrollbar {
  height: 8px;
  background-color: rgba(128, 128, 128, 0.274);
}

.blogCode::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
}

.mainBlogSection .article p {
  font-size: 15px;
  color: var(--white-color);
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 1.9;
  margin-bottom: 20px;
}
.mainBlogSection article ul,
.mainBlogSection article li {
  list-style-type: none;
  margin: 10px 0;
  line-height: 1.7;
  margin: 24px;
  margin-left: 44px;
}
.mainBlogSection article figure img {
  width: 50%;
  margin: 10px 50%;
  transform: translateX(-50%);
}
.mainBlogSection article strong {
  color: var(--white-color);
  font-weight: 600;
}
.mainBlogSection article em {
  font-weight: 300;
  color: var(--white-color);
}
.mainBlogSection article li {
  font-size: 13px;
  position: relative;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin: 5px 0;
}
.mainBlogSection article p a {
  color: var(--white-color);
  font-style: italic;
}
.mainBlogSection article p a:hover {
  color: var(--main-color);
}
.mainBlogSection article ul li::before {
  content: '';
  position: absolute;
  top: 4px;
  left: -25px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: solid 2px #ffc107;
  background: transparent;
  box-shadow: 0 3px 8px 0 rgb(15 15 20 / 20%);
}

/* Experience */

.experienceSection {
  display: flex;
  margin-top: 10px;
}
.timeLineSeperator {
  flex-basis: 50%;
}
.timeLineSection {
  margin-right: 30px;
  border-right: 4px solid var(--background-color);
  margin-top: 10px;
  padding-right: 30px;
}
.timelineItem {
  display: flex;
  flex-direction: column;
  background-color: var(--elements-back);
  padding: 30px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 20px;
  position: relative;
  border-radius: 10px;
}

.timelinecircle {
  position: absolute;
  display: block;
  border: 3px solid var(--main-color);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 50%;
  background-color: var(--background-color);
  right: -40px;
}

.timelineItem:hover > .timelinecircle {
  -webkit-animation: pulse-animation 0.85s infinite;
  animation: pulse-animation 0.85s infinite;
}

.timelinetop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.timelineItem .sectionTitle {
  font-size: 14px;
  color: var(--white-color);
  font-weight: 600;
}
.timelineItem em {
  color: #646466;
  font-size: 11px;
  font-weight: 300;
}
.timelinetopRight {
  background-color: var(--background-color);
  font-size: 10px;
  border-radius: 15px;
  padding: 5px 15px;
  letter-spacing: 0.5px;
  font-weight: 300;
  color: #8c8c8e;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: inset 0 3px 8px 0 rgb(15 15 20 / 20%);
}
p.timeLineBody {
  color: #8c8c8e;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
}
.timelineItem .serviceBoxLink {
  font-size: 11px;
}
.timelineItem .serviceBoxLink::after {
  font-size: 13px;
}

/* Project Section */

.projectSection {
  margin-top: 20px;
  width: 100%;
}
.projectCategories {
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
}
.projectCategories li.sectionTitle {
  list-style-type: none;
  cursor: pointer;
  margin-right: 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
}
.projectCategories li.sectionTitle:last-child {
  margin-right: 0;
}
.react-tabs__tab--selected {
  color: var(--main-color);

  border-bottom: 1px solid #fff;
}

.projects {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: row;
  margin-top: 30px;
  flex-wrap: wrap;
}
.projectBox {
  margin-right: 15px;
  border-radius: 10px 10px 0 0;
  background-color: var(--elements-back);
  flex-basis: 32%;
  /* width: 23rem; */
  /* width: 32%; */
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-animation: projectGrow 0.4s;
  animation: projectGrow 0.4s;
}

@-webkit-keyframes projectGrow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes projectGrow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.projectDataDescription {
  padding: 5px 25px 25px 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.projectImage img {
  border-radius: 10px 10px 0 0;
  width: 100%;
}
.projectDataDescription .sectionTitle {
  font-size: 18px;
}
.projectTags {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}
.projectTags span {
  padding: 5px 10px;
  font-size: 11px;
  background-color: var(--background-color);
  margin: 0 5px;
  border-radius: 10px;
  color: #8c8c8e;
  font-style: italic;
}
p.projectDescription {
  font-size: 12px;
}
.projectLinks {
  width: 100%;
}
.allProjectLinks {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  padding: 0 25px 25px 25px;
}
.projectLinks a {
  font-size: 18px;
  display: flex;
  align-items: center;
  transition: 0.2s ease-in-out;
}

.githubLink a::after {
  content: ' GitHub';
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
  transition: 0.2s ease-in-out;
  font-weight: 600;
}
.livelink a::after {
  content: ' Website';
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
  transition: 0.2s ease-in-out;
  font-weight: 600;
}

.projectLinks a > svg {
  fill: var(--main-color);
}
.projectLinks a:hover {
  color: var(--main-color);
}
/* MainSection */
.mainSection {
  background-image: linear-gradient(
      180deg,
      rgba(30, 30, 40, 0.911) 0%,
      rgba(30, 30, 40, 0.897) 70%,
      rgba(30, 30, 40, 0.877) 80%,
      #1e1e28 100%
    ),
    url(./assets/Images/bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
  height: 95vh;
  margin-left: 5px;
  border-radius: 10px;
  width: 100%;
}
.paddingMainSection {
  padding: 10px 30px;
  min-height: 93%;
}
@media only screen and (max-width: 1579px) {
  .bannerImg {
    display: none;
  }
  .projectBox {
    margin-right: 15px;
    border-radius: 10px 10px 0 0;
    background-color: var(--elements-back);
    flex-basis: 48%;
    /* width: 23rem; */
    /* width: 32%; */
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1439px) {
  .counterName {
    margin-left: 0;
  }
}
@media only screen and (max-width: 1404px) {
  .contactBox {
    flex-basis: 100%;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 1380px) {
  .bannerText {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .banner {
    margin-top: 0px;
    border-radius: 0;
  }
  .bannerText h1 {
    font-size: 46px;
  }
  .bannerContents {
    justify-content: center;
    padding: 0;
  }

  .bannerOverlay {
    padding: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 1100px) {
  body {
    overflow-x: hidden;
    width: 100vw;
  }
  .master {
    margin: 0px;
  }
  .blogPostBox {
    border-radius: 0;
  }
  .sideBar {
    position: fixed;
    left: -300px;
    height: 95vh;
    border-radius: 0px;

    /* overflow: hidden; */
    width: 290px;
    z-index: 5;
    transition: 0.5s ease-in-out;
  }
  .banner,
  .contactBox,
  .contactForm,
  .serviceBox,
  .commonButton,
  .contactForm span input,
  .mainBlogSection article,
  .authorSection .profileDetailsSection,
  .blogPostBanner {
    border-radius: 0;
  }
  .sectionTitle {
    text-align: center;
  }
  .postBoxTitle {
    text-align: left;
  }
  #hideSideBar {
    left: 0;
    transition: 0.5s ease-in-out;
    z-index: 100;
  }
  .paddingMainSection {
    padding: 65px 10px 30px;
  }
  .banner {
    height: 340px;
  }
  nav span {
    margin-bottom: 25px;
  }
  .mainSection nav {
    background-color: #191923;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-start;
    top: 0;
    right: -400px;
    width: 220px;
    padding: 100px 40px 0;
    height: 100vh;
    transition: 0.5s ease-in-out;
  }

  nav#hidden {
    right: 0;
    transition: 0.5s ease-in-out;
    z-index: 100;
  }
  .mobileArtApp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 50px;
    background-color: #20202a;
    z-index: 1;
  }
  .menuIcon,
  .closeIcon,
  .sideBarIcon {
    display: inherit;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 10;
    cursor: pointer;
  }
  .sideBarIcon {
    left: 20px;
    font-size: 20px;
    transition: 0.5s ease-in-out;
  }
  .closeIcon {
    display: none;
  }
  #menuIconVisibility {
    display: none;
  }
  #closeIconVisibility {
    display: inherit;
    z-index: 101;
  }
  nav {
    position: fixed;
    right: 10px;
    z-index: 5;
  }
  .menuSection nav#hidden {
    position: absolute;
  }
  #moveDots {
    left: 250px;
    transition: 0.5s ease-in-out;
    z-index: 101;
  }
  .navLink {
    margin-right: 0;
    font-size: 18px;
  }
  .navLinks span {
    margin-right: 0;
  }
  .counterName {
    margin-left: -80px;
  }
  img.gotoHome {
    width: 35px;
    display: inherit;
    position: fixed;
    top: 0;
    z-index: 4;
    margin-left: 50%;
    transform: translateX(-50%);
    padding-top: 8px;
  }
  #sidebarCover {
    display: block;
    position: absolute;
    width: 100vw;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.527);
    height: 100vh;
  }
  .navbarCover {
    right: 0;
    transition: 0.3s ease-in-out;
    display: block;
    position: absolute;
    top: 0;
    z-index: 1000;
    height: 100vh;
    width: 0;
  }
  #navbarCover {
    width: 100vw;
    right: 299px;
    transition: 0.7s ease-in-out;
    background-color: rgba(0, 0, 0, 0.685);
  }
  .mainBlogSection {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
  .mainBlogSection article,
  .mainBlogSection .authorSection {
    background-color: var(--elements-back);
  }
  .mainBlogSection article {
    margin-bottom: 15px;
    max-width: 100%;
  }
  .blogPostBanner img {
    -webkit-filter: brightness(1);
    filter: brightness(1);
  }
  .blogTitle {
    font-size: inherit;
    margin: inherit;
  }
  .blogCode {
    margin: 10px 0px;
    font-size: 0.7rem;
  }
  .article {
    padding: 10px;
  }
  .experienceSection {
    flex-direction: column;
  }
  .timeLineSection {
    margin-right: 10px;
    padding-right: 15px;
  }
  .timelinecircle {
    right: -25px;
  }
  .experienceSection .sectionTitle {
    text-align: left;
  }
  .projectBox {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    flex-basis: 47%;
  }
}

@media only screen and (max-width: 996px) {
  .serviceBox {
    flex-basis: 42%;
  }
  .contactSection .sectionTitle {
    margin: 15px 0;
  }
  .blogCode {
    margin: 10px 0px;
    font-size: 0.45rem;
  }
}

@media only screen and (max-width: 828px) {
  .serviceBox {
    flex-basis: 100%;
  }
  .projectBox {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    flex-basis: 100%;
  }
}

@media only screen and (max-width: 769px) {
  .bannerText h1 {
    font-size: 32px;
  }
  .counterSection {
    flex-direction: column;
  }
  .counterSection div.mrgbtm {
    margin-bottom: 20px;
  }
  .counterName {
    font-size: 12px;
    margin-left: 0;
  }
  .blogPostBox {
    flex-basis: 100%;
    background-color: var(--elements-back);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-bottom: 20px;
  }
  footer {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 613px) {
  .bannerText h1 {
    font-size: 32px;
  }
  img.gotoHome {
    margin-left: 46vw;
  }
  .timelinetop {
    flex-direction: column;
  }
  .timelinetopRight {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 10px;
  }
}
